body {
  margin: 0;
  width: 100%;
}

.stamp-protocol-home-container {
  display: inline-flex;
}
.stamp-protocol-home-stamp-protocol-home {
  width: 1440px;
  height: 3768px;
  overflow: clip;
  position: relative;
  background-color: white;
}
.stamp-protocol-home-unsplash-qusxjo_d4lu {
  top: 1938px;
  left: 136px;
  width: 1200px;
  height: 428px;
  position: absolute;
  box-sizing: border-box;
  background-color: rgba(29, 48, 71, 1);
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.stamp-protocol-home-rectangle1 {
  top: 0;
  left: 0;
  width: 1440px;
  height: 650px;
  position: absolute;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-footer {
  top: 3461px;
  left: 1px;
  width: 1440px;
  height: 307px;
  position: absolute;
}
.stamp-protocol-home-rectangle8 {
  top: 0;
  left: 0;
  width: 1440px;
  height: 307px;
  position: absolute;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-integrate-stamps {
  top: 94px;
  left: 720px;
  color: rgba(248, 241, 227, 1);
  width: 114px;
  height: 25px;
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-begin-stamping {
  top: 119px;
  left: 720px;
  color: white;
  width: 135px;
  height: 25px;
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-get-vouched {
  top: 144px;
  left: 720px;
  color: rgba(248, 241, 227, 1);
  width: 135px;
  height: 25px;
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-information {
  top: 58px;
  left: 720px;
  color: rgba(248, 241, 227, 1);
  width: 114px;
  height: 20.36px;
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-creating-a-new-way-t {
  top: 94.49px;
  left: 911px;
  color: rgba(248, 241, 227, 1);
  width: 288px;
  height: 74.37px;
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-protocol {
  top: 58px;
  left: 911px;
  color: rgba(248, 241, 227, 1);
  width: 158px;
  height: 20.74px;
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-community {
  top: 58px;
  left: 1245px;
  color: rgba(248, 241, 227, 1);
  width: 158px;
  height: 20.74px;
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-protocol-by-pe {
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-protocol-by-text0 {
  color: rgba(248, 241, 227, 1);
  display: inline;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-perma-pages-text1 {
  color: rgba(113, 137, 255, 1);
  display: inline;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  text-decoration: underline;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-permement-on-arweave1 {
  top: 68px;
  left: 62px;
  width: 162px;
  height: 82px;
  position: absolute;
  background-size: cover;
  background-image: url(https://uortjlczjmucmpaqqhqm.supabase.co/storage/v1/object/public/firejet-converted-images/7426/49f33dd59f4669df4c4d05c9211895e3b57fe9c7.webp);
}
.stamp-protocol-home-container1 {
  display: inline-flex;
}
.stamp-protocol-home-frame2 {
  top: 650px;
  left: 0;
  width: 1440px;
  height: 649px;
  overflow: clip;
  position: absolute;
  background-color: rgba(248, 241, 227, 1);
}
.stamp-protocol-home-stamps1 {
  top: 50px;
  left: 962px;
  width: 398px;
  height: 275px;
  position: absolute;
  background-size: cover;
  background-image: url(https://uortjlczjmucmpaqqhqm.supabase.co/storage/v1/object/public/firejet-converted-images/7426/4a9c1b39fb9a8e9eb5a6b0e9ac643cdd0fab9d2b.webp);
}
.stamp-protocol-home-how-does-stamp-proto {
  top: 170px;
  left: 75px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 48px;
  text-align: right;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-protocol-allow {
  top: 278px;
  left: 80px;
  width: 1280px;
  height: 371px;
  position: relative;
}
.stamp-protocol-home-stamp-protocol-allow-text0 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-permaweb-text1 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  text-decoration: underline;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-when-a-user-stamp-text2 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-content-creator-text3 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-style: italic;
  font-family: Ubuntu;
  line-height: 43.5px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-receives-stampcoins-text4 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-vouch-d-a-o-text5 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  text-decoration: underline;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-to-verify-users-hum-text6 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-arweave-text7 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  text-decoration: underline;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-this-creates-marke-text8 {
  color: rgba(29, 48, 71, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  line-height: 43.5px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-header-menu {
  top: 42.12px;
  left: 120px;
  width: 1243px;
  height: 30.88px;
  position: absolute;
}
.stamp-protocol-home-get-vouched1 {
  top: 8.88px;
  left: 818px;
  color: rgba(248, 241, 227, 1);
  width: 91px;
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-begin-stamping1 {
  top: 8.88px;
  left: 652px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-integrate-stamps1 {
  top: 8.88px;
  left: 478px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-protocol1 {
  top: 2.88px;
  left: 0;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 24px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp1 {
  top: -2.12px;
  left: -50px;
  width: 39px;
  height: 39px;
  position: absolute;
  background-size: cover;
  background-image: url(https://uortjlczjmucmpaqqhqm.supabase.co/storage/v1/object/public/firejet-converted-images/7426/ddcb48b641d6fde001952d6ac7d5ba12e6416768.webp);
}
.stamp-protocol-home-rectangle3 {
  top: 146px;
  left: 185px;
  width: 461px;
  height: 513px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(248, 241, 227, 1);
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 120px;
  border-bottom-right-radius: 120px;
}
.stamp-protocol-home-a-world-without-ads {
  top: 227px;
  left: 839px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 48px;
  text-align: right;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rectangle2 {
  top: 540px;
  left: 1162px;
  width: 158px;
  height: 38px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: rgba(113, 137, 255, 1);
}
.stamp-protocol-home-get-stamping {
  top: 551px;
  left: 1197px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rewards-for-content {
  top: 333px;
  left: 739px;
  width: 581px;
  position: relative;
  text-align: right;
}
.stamp-protocol-home-rewards-for-content-text0 {
  color: rgba(255, 255, 255, 0.6);
  display: inline;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-protocol-fixes-text1 {
  color: rgba(248, 241, 227, 1);
  display: inline;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-untitled2fade1 {
  top: 176px;
  left: 109px;
  width: 576.16px;
  height: 571px;
  position: absolute;
  background-size: cover;
  background-image: url(https://uortjlczjmucmpaqqhqm.supabase.co/storage/v1/object/public/firejet-converted-images/7426/e49b763d7c8081c9d3ba7363aee0d5875b40ac5e.webp);
}
.stamp-protocol-home-ellipse1 {
  top: 1552px;
  left: 281px;
  width: 68px;
  height: 68px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 9999px;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-fluent-leaf-two16regular {
  top: 1576px;
  left: 295px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.stamp-protocol-home-how-do-i-stamp {
  top: 1387px;
  left: 514px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 48px;
  text-align: right;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-while-you-re-browsin {
  top: 1472px;
  left: 397px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  text-align: center;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-ellipse2 {
  top: 1552px;
  left: 685px;
  width: 68px;
  height: 68px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 9999px;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-ellipse3 {
  top: 1552px;
  left: 1076px;
  width: 68px;
  height: 68px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 9999px;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-get-vouched2 {
  top: 1640px;
  left: 254px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-get-a-vouchdao-verif {
  top: 1684px;
  left: 158px;
  color: rgba(29, 48, 71, 1);
  width: 315px;
  display: inline;
  position: absolute;
  font-size: 14px;
  text-align: center;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-browse-the-permaweb {
  top: 1640px;
  left: 613px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-look-for-the-stamp-p {
  top: 1684px;
  left: 539px;
  color: rgba(29, 48, 71, 1);
  width: 360px;
  display: inline;
  position: absolute;
  font-size: 14px;
  text-align: center;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-stamp-content {
  top: 1640px;
  left: 1035px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-connect-your-wallet {
  top: 1684px;
  left: 952px;
  color: rgba(29, 48, 71, 1);
  width: 315px;
  display: inline;
  position: absolute;
  font-size: 14px;
  text-align: center;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-unsplash-qusxjo_d4lu1 {
  top: 2959px;
  left: 120px;
  width: 1200px;
  height: 428px;
  position: absolute;
  box-sizing: border-box;
  background-color: rgba(113, 137, 255, 1);
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.stamp-protocol-home-stamp-protocol-is-on {
  top: 3145px;
  left: 287px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  text-align: center;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-want-to-look-under-t {
  top: 3057px;
  left: 370px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 48px;
  text-align: center;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rectangle21 {
  top: 3250px;
  left: 605px;
  width: 225px;
  height: 48px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: rgba(248, 241, 227, 1);
}
.stamp-protocol-home-learn-more {
  top: 3262px;
  left: 664px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-unsplash-qusxjo_d4lu2 {
  top: 1924px;
  left: 120px;
  width: 1200px;
  height: 428px;
  position: absolute;
  box-sizing: border-box;
  background-color: rgba(248, 241, 227, 1);
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.stamp-protocol-home-permapages-is-the-fi {
  top: 2124px;
  left: 309px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  text-align: center;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-add-stamps-to-your-p {
  top: 2022px;
  left: 361px;
  color: rgba(29, 48, 71, 1);
  display: inline;
  position: absolute;
  font-size: 48px;
  text-align: center;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rectangle22 {
  top: 2239px;
  left: 626px;
  width: 225px;
  height: 48px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-visit-permapages {
  top: 2252px;
  left: 658px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-first-to-stamp {
  top: 2514px;
  left: 65px;
  width: 1310px;
  height: 486px;
  position: absolute;
}
.stamp-protocol-home-good-content-will-be {
  top: 135px;
  left: 15px;
  color: rgba(29, 48, 71, 1);
  width: 549px;
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  line-height: 28px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-component {
  top: 0;
  left: 729px;
  width: 567px;
  filter: drop-shadow(14px 9px 21px rgba(0, 0, 0, 0.34));
  height: 486px;
  position: absolute;
  background-size: cover;
  background-image: url(https://uortjlczjmucmpaqqhqm.supabase.co/storage/v1/object/public/firejet-converted-images/7426/3de88e01336b40ee3b054e492cf99ac3f962937e.webp);
}
.stamp-protocol-home-be-the-first-to-stam {
  top: 0;
  left: 10px;
  color: rgba(29, 48, 71, 1);
  width: 630px;
  height: 139px;
  display: inline;
  position: absolute;
  font-size: 48px;
  font-family: Ubuntu;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rectangle16 {
  top: 268px;
  left: 10px;
  width: 250px;
  height: 67px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 13px;
  background-color: rgba(113, 137, 255, 1);
}
.stamp-protocol-home-rectangle17 {
  top: 268px;
  left: 280px;
  width: 250px;
  height: 67px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 13px;
  background-color: rgba(29, 48, 71, 1);
}
.stamp-protocol-home-create-a-page {
  top: 269px;
  left: 328px;
  color: white;
  display: inline;
  position: absolute;
  font-size: 24px;
  text-align: center;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-browse-pages {
  top: 269px;
  left: 58px;
  color: white;
  display: inline;
  position: absolute;
  font-size: 24px;
  text-align: center;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: 65px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rectangle23 {
  top: 1778px;
  left: 236px;
  width: 158px;
  height: 38px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: rgba(113, 137, 255, 1);
}
.stamp-protocol-home-get-vouched3 {
  top: 1789px;
  left: 273px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.stamp-protocol-home-rectangle31 {
  top: 1778px;
  left: 641px;
  width: 158px;
  height: 38px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: rgba(113, 137, 255, 1);
}
.stamp-protocol-home-get-stamping1 {
  top: 1789px;
  left: 676px;
  color: rgba(248, 241, 227, 1);
  display: inline;
  position: absolute;
  font-size: 14px;
  font-family: Ubuntu;
  font-weight: 500;
  line-height: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
